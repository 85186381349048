/* 368px */

/* 480px */

/* 768px */

/* 992px */

/* 1280px */

/* 1536px */

:root {
  --font-secondary: Inter;
  --font-primary: Outfit;
  --color-alert: #B75252;
  --color-warning: #C2AA55;
  --color-success: #5AA983;
  --color-neutral-1000: #000000;
  --color-neutral-900: #1B1F28;
  --color-neutral-800: #222631;
  --color-neutral-700: #303544;
  --color-neutral-600: #3B4254;
  --color-neutral-500: #464C5F;
  --color-neutral-400: #6A6F83;
  --color-neutral-300: #80869D;
  --color-neutral-200: #BDC1CF;
  --color-neutral-100: #E2E4ED;
  --color-neutral-0: #FFFFFF;
  --color-secondary-dark: #B99247;
  --color-secondary-light: #F0D9B0;
  --color-secondary-base: #DCB467;
  --color-primary-light: #b5cab6;
  --color-primary-dark: #566d57;
  --color-primary-base: #849b85;

  --content-layout-background-color: var(--color-neutral-900);
  --sidebar-background-color: var(--color-neutral-700);

  --z-index-header: 500;
  --z-index-sidebar: 600;
  --floating-chat-window-z-index: 700;
  --z-index-modal-overlay: 790;
  --z-index-modal: 800;
  --z-index-dropdown: 900;
  --z-index-dragging: 1000;
  --z-index-overlay: 1000;
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
}

body {
  --color-body: var(--color-neutral-200);

  max-width: 100vw;
  padding: 0;
  margin: 0;
  background: var(--content-layout-background-color);
  color: var(--color-body);
  font-family: var(--font-secondary);
  line-height: 1.33;
}

body:fullscreen::backdrop {
      background: var(--content-layout-background-color);
    }

body:fullscreen main {
      position: fixed;
      overflow: auto;
      max-width: 100vw;
      max-height: 100vh;
      padding: 0.75rem;
      inset: 0;
      overflow-x: hidden;
    }

body:fullscreen #sidebar {
      display: none;
    }

body:fullscreen #panel-layout {
      padding: 0;
      margin: 0;
      grid-column: 1 / -1;
    }

body:fullscreen #topbarheader {
      display: none;
    }

body ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

body ::-webkit-scrollbar-track {
    background: var(--color-neutral-700);
  }

body ::-webkit-scrollbar-thumb {
    border-radius: 0.75rem;
    background: var(--color-primary-base);
  }

body ::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-dark);
  }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-neutral-0);
  font-family: var(--font-primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  max-width: 50rem;
}

button {
  font-family: var(--font-primary);
}

button:hover {
    cursor: pointer;
  }

p {
  margin-top: 0;
}

input {
  outline: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

a {
  color: var(--color-neutral-0);
  font-family: var(--font-primary);
  text-decoration: none;
}

fieldset {
  padding: 0;
  border: none;
}

fieldset legend {
    margin-bottom: 0.75rem;
    font-size: 0.75rem;
    font-weight: 600;
  }

textarea {
  border: none;
  background: inherit;
  color: var(--color-body);
  font-family: inherit;
  resize: none;
}

textarea::-moz-placeholder {
    color: var(--color-neutral-200);
    font-family: inherit;
    opacity: 1;
  }

textarea::placeholder {
    color: var(--color-neutral-200);
    font-family: inherit;
    opacity: 1;
  }

textarea:focus {
    outline: none;
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  box-shadow: inset 0 0 0px 0px var(--color-neutral-900);
  -webkit-text-fill-color: var(--color-neutral-0);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

button {
  border: none;
  background: none;
}

iframe {
  border: none;
}

*::view-transition-old(),
*::view-transition-new() {
  animation-fill-mode: backwards;
}

.tippy-box[data-theme='exerciseSuggestions'] {
  left: -3.25rem;
  background: transparent;
}

.tippy-box[data-theme='exerciseSuggestions'] > .tippy-content {
    padding: 0;
  }

hr {
  border: none;
  border-top: 1px solid var(--color-neutral-500);
  margin: 1rem 0;
}

/* latin-ext */
@font-face {
  font-family: '__Outfit_ad50fd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Outfit_ad50fd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Outfit_Fallback_ad50fd';src: local("Arial");ascent-override: 100.18%;descent-override: 26.05%;line-gap-override: 0.00%;size-adjust: 99.82%
}.__className_ad50fd {font-family: '__Outfit_ad50fd', '__Outfit_Fallback_ad50fd';font-style: normal
}.__variable_ad50fd {--font-primary: '__Outfit_ad50fd', '__Outfit_Fallback_ad50fd'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-secondary: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

