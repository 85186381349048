/* 368px */
@custom-media --xs (min-width: 23em);

/* 480px */
@custom-media --sm (min-width: 30em);

/* 768px */
@custom-media --md (min-width: 48em);

/* 992px */
@custom-media --lg (min-width: 62em);

/* 1280px */

@custom-media --xl (min-width: 80em);

/* 1536px */
@custom-media --xxl (min-width: 96em);

@custom-media --2xxl (min-width: 120em);

:root {
  @import-json './styles.json';

  --content-layout-background-color: var(--color-neutral-900);
  --sidebar-background-color: var(--color-neutral-700);

  --z-index-header: 500;
  --z-index-sidebar: 600;
  --floating-chat-window-z-index: 700;
  --z-index-modal-overlay: 790;
  --z-index-modal: 800;
  --z-index-dropdown: 900;
  --z-index-dragging: 1000;
  --z-index-overlay: 1000;
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
}

body {
  --color-body: var(--color-neutral-200);

  max-width: 100vw;
  padding: 0;
  margin: 0;
  background: var(--content-layout-background-color);
  color: var(--color-body);
  font-family: var(--font-secondary);
  line-height: 1.33;

  &:fullscreen {
    &::backdrop {
      background: var(--content-layout-background-color);
    }

    & main {
      position: fixed;
      overflow: auto;
      max-width: 100vw;
      max-height: 100vh;
      padding: 0.75rem;
      inset: 0;
      overflow-x: hidden;
    }

    & #sidebar {
      display: none;
    }

    & #panel-layout {
      padding: 0;
      margin: 0;
      grid-column: 1 / -1;
    }

    & #topbarheader {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    background: var(--color-neutral-700);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.75rem;
    background: var(--color-primary-base);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary-dark);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-neutral-0);
  font-family: var(--font-primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  max-width: 50rem;
}

button {
  font-family: var(--font-primary);

  &:hover {
    cursor: pointer;
  }
}

p {
  margin-top: 0;
}

input {
  outline: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

a {
  color: var(--color-neutral-0);
  font-family: var(--font-primary);
  text-decoration: none;
}

fieldset {
  padding: 0;
  border: none;

  & legend {
    margin-bottom: 0.75rem;
    font-size: 0.75rem;
    font-weight: 600;
  }
}

textarea {
  border: none;
  background: inherit;
  color: var(--color-body);
  font-family: inherit;
  resize: none;

  &::placeholder {
    color: var(--color-neutral-200);
    font-family: inherit;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  box-shadow: inset 0 0 0px 0px var(--color-neutral-900);
  -webkit-text-fill-color: var(--color-neutral-0);
  transition: background-color 5000s ease-in-out 0s;
}

button {
  border: none;
  background: none;
}

iframe {
  border: none;
}

*::view-transition-old(),
*::view-transition-new() {
  animation-fill-mode: backwards;
}

.tippy-box[data-theme='exerciseSuggestions'] {
  left: -3.25rem;
  background: transparent;

  & > .tippy-content {
    padding: 0;
  }
}

hr {
  border: none;
  border-top: 1px solid var(--color-neutral-500);
  margin: 1rem 0;
}
